/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

export const analyticsSetCompanyId = companyId => {
	window.dataLayer = window.dataLayer || [];

	window.dataLayer.push({
		event: 'setCompanyId',
		company_id: companyId,
	});
};
