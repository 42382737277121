/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import * as momentTimezone from 'moment-timezone';
import moment from 'moment';
import {
	getTimeOptionMinutes,
	tTimeOptions,
	isTimeInMinutes,
	formatTradingSessionTime,
} from 'src/_helpers/timeRange';
import { Languages } from 'src/constants/settings';
import { formatTzOffset } from 'src/components/DateTimeTzPicker';
import { ContractMonthCodes } from 'src/constants/contract';

const MINUTE_IN_SECONDS = 60;
const HOUR_IN_SECONDS = MINUTE_IN_SECONDS * 60;
const DAY_IN_SECONDS = HOUR_IN_SECONDS * 24;

export const millisecondsRemaining = isoDateTime => {
	const now = new Date();
	const endDate = new Date(isoDateTime);

	return moment(endDate).diff(now, 'milliseconds');
};

export const getDistanceInSeconds = ({ startDate, endDate }) => {
	const diffInSeconds = moment(endDate).diff(startDate, 'seconds');

	return Math.max(diffInSeconds, 0);
};

export const formatDistance = ({
	t,
	startDate,
	endDate,
	expiredKey,
	fullText = false,
	ago = false,
	short = false,
}) => {
	const diffInSeconds = ago
		? moment(startDate).diff(endDate, 'seconds')
		: moment(endDate).diff(startDate, 'seconds');

	if (expiredKey && diffInSeconds < 0) {
		return t(expiredKey);
	}

	if (diffInSeconds < MINUTE_IN_SECONDS) {
		return fullText
			? ago
				? t('less_than_1_minute_ago')
				: t('less_than_1_minute')
			: `< 1${t('minutes_abbrev')}`;
	}

	const days = Math.floor(diffInSeconds / DAY_IN_SECONDS);
	const hours = Math.floor((diffInSeconds % DAY_IN_SECONDS) / HOUR_IN_SECONDS);
	const minutes = Math.floor((diffInSeconds % HOUR_IN_SECONDS) / MINUTE_IN_SECONDS);

	// special case - when there's exactly 1 day, we show 24 h
	if (days === 1 && hours === 0 && minutes === 0) {
		return `24${t('hours_abbrev')}`;
	}

	const result = [
		days && `${days}${t('days_abbrev')}`,
		hours && `${hours}${t('hours_abbrev')}`,
		minutes && `${minutes}${t('minutes_abbrev')}`,
		ago && t('ago'),
	].filter(Boolean);

	if (short) {
		return result.slice(0, 2).join(' ');
	}

	return result.join(' ');
};

export const toUTCDateString = date =>
	new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();

export const toUTCUnix = date => date.getTime() - date.getTimezoneOffset() * 60000;

export const parseDateStringAsUTC = (dateString = '') => new Date(dateString.replace('Z', ''));

export const formatDateMonthYear = date => moment(parseDateStringAsUTC(date)).format('MMM YYYY');

export function dateEqualByDay(d1, d2) {
	return moment(d1).isSame(d2, 'day') && moment(d1).isSame(d2, 'year');
}

export const dateEqualBySeconds = (d1, d2) => {
	return moment(d1).isSame(d2, 'seconds');
};

/*
dateformat mapped to delivery_mode to the backend

delivery_mode == “days” (validation for it could be: allow dates >= today)
delivery_mode == “months” (validation for it: allow dates >= 1st day of current month)
*/
export const Dateformat = {
	Days: 'days',
	Months: 'months',
	Quarters: 'quarters',
	Continuous: 'continuous',
	Time: 'time',
};

export const formatDateRangeShipmentDays = (startDate, endDate) => {
	const momentStart = moment(startDate);
	const momentEnd = moment(endDate);

	const line1 = `${momentStart.format('D MMM')} - ${momentEnd.format('D MMM')}`;

	const yearStart = momentStart.format('YYYY');
	const yearEnd = momentEnd.format('YYYY');

	const line2 = yearStart === yearEnd ? yearStart : `${yearStart}/${momentEnd.format('YY')}`;

	return [line1, line2];
};

export function formatDateRange({ startDate, endDate, format, shortYear = false }) {
	if ([Dateformat.Months, Dateformat.Quarters].includes(format)) {
		return formatDateRangeInMonths(startDate, endDate, shortYear);
	}

	return formatDateRangeInDays({
		startDate,
		endDate,
	});
}

export const isFirstDayOfMonth = date => {
	if (!date) {
		return false;
	}

	return moment(date).date() === 1;
};

export const isLastDayOfMonth = date => {
	if (!date) {
		return false;
	}
	const d = moment(date);
	const currentMonth = d.month();
	const nextDayMonth = d.add(1, 'day').month();

	return currentMonth !== nextDayMonth;
};

export function formatDateRangeInDays({ startDate, endDate }) {
	return `${moment(startDate).format('ll')} - ${moment(endDate).format('ll')}`;
}

export const getNumberOfMonths = (startDate, endDate) => {
	if (!startDate || !endDate) {
		return 0;
	}

	const start = moment(startDate).utc(false);
	const end = moment(endDate).utc(false);

	return 1 + getAbsoluteMonths(end) - getAbsoluteMonths(start);
};

const getAbsoluteMonths = momentDate => {
	let months = Number(momentDate.format('MM'));
	let years = Number(momentDate.format('YYYY'));
	return months + years * 12;
};

const formatDateRangeInMonthsChinese = dates => {
	let years = [];
	let isLongDateWithDots = false;

	dates.forEach(date => {
		if (date === longDateDots) {
			isLongDateWithDots = true;
			return;
		}

		const month = date.format('M');
		const year = date.format('YYYY');

		const lastYear = years[years.length - 1];
		if (lastYear?.year === year) {
			lastYear.months.push(month);
		} else {
			years.push({ year, months: [month] });
		}
	});

	if (isLongDateWithDots && years.length > 1) {
		years = [years.at(0), years.at(-1)];
	}

	return years
		.map(year => {
			const monthsArrary = isLongDateWithDots ? [year.months[0]] : year.months;
			const formattedYear = years.length === 1 ? year.year : year.year.substring(2);

			if (monthsArrary.length === 1) {
				return `${formattedYear}年${monthsArrary[0]}月`;
			}

			return `${formattedYear}年${monthsArrary[0]}-${
				monthsArrary[monthsArrary.length - 1]
			}月`;
		})
		.join(' - ');
};

const longDateDots = '..';

const formatDateRangeInMonthsEnglish = (dates, shortYear) => {
	const letters = dates
		.map(d => {
			const singleMonth = dates.length === 1;
			/**
			 * WE NEED TO FORCE LOCALE CAUSE WHEN TESTING CHANGES IN CHINESE AND SWITCHING
			 * TO EN DATES WOULD COME ON CHINESE FORMAT
			 * */
			if (d === longDateDots) {
				return d;
			}

			const multiLetter = d.locale('en').format('MMM');

			return singleMonth ? multiLetter : multiLetter[0];
		})
		.join('');

	const yearFormat = shortYear ? 'YY' : 'YYYY';

	const year =
		dates.length > 0
			? dates[0].format(yearFormat) === dates[dates.length - 1].format(yearFormat)
				? dates[0].format(yearFormat)
				: dates[0].format(yearFormat) + '/' + dates[dates.length - 1].format('YY')
			: '';

	return `${letters} ${year}`;
};

export function formatDateRangeInMonths(startDate, endDate, shortYear) {
	let dates = [];
	const fromDate = moment(startDate).clone();
	const toDate = moment(endDate).clone();
	// +1 because we want to include the last month
	const diff = toDate.diff(fromDate, 'M') + 1;

	if (diff > 6) {
		dates = [
			fromDate,
			moment(startDate).add(1, 'M'),
			longDateDots,
			moment(startDate).add(diff - 2, 'M'),
			moment(startDate).add(diff - 1, 'M'),
		];
	} else {
		for (let i = 0; i < diff; i++) {
			dates.push(moment(startDate).add(i, 'M'));
		}
	}

	const isChinese = moment.locale() === Languages.Chinese.moment;

	if (isChinese) {
		return formatDateRangeInMonthsChinese(dates);
	}

	return formatDateRangeInMonthsEnglish(dates, shortYear);
}

export const setSixPM = date => {
	date.setHours(18, 0, 0);

	return date;
};

export const setNoon = date => {
	date.setHours(12, 0, 0);

	return date;
};

export const getIntentionEndDate = tradeData => {
	if (!tradeData) {
		return '';
	}

	const timeZoneOffset = new Date().getTimezoneOffset() / -60;

	return moment
		.utc(tradeData.delivery_date_from)
		.subtract(tradeData.pre_shipment_duration, 'days')
		.add(timeZoneOffset, 'hours')
		.subtract(1, 'minute');
};

export const getFormattedDateTime = (value, t) => {
	if (value?.time) {
		if (isTimeInMinutes(value.time)) {
			return tTimeOptions(t, getTimeOptionMinutes(value.time));
		} else {
			return formatTradingSessionTime(value.time);
		}
	} else {
		const shortTimezone = value?.tz
			? ' ' + (value.tz.includes('/') ? value.tz.split('/')[1].replace('_', ' ') : value.tz)
			: '';

		if (value?.localDate) {
			const momentDate = moment(value.localDate);
			const localizedDate = momentDate.locale(moment.locale()).format('MMM D, YYYY, h:mm A');

			return localizedDate + shortTimezone;
		}

		return '';
	}
};

export const getFormattedDate = value => {
	if (value.localDate) {
		const momentDate = moment(value.localDate);
		return momentDate.format('ll');
	}

	return '';
};

export const isExpired = validity => moment().isAfter(validity);

export const formatValidity = (validity, t) => {
	const date = moment(validity).format('lll');
	const timeZone = getLocalTimeZone(t);

	return `${date} ${timeZone}`;
};

export const formatValidityShort = validity => moment(validity).format('lll');

export const formatCreatedDate = createdDate => moment(createdDate).format('D MMMM YYYY, hh:mm A');

export const getLocalTimeZone = t => {
	const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	const offset = momentTimezone.tz(timeZone).utcOffset();

	const label = t('local_time');

	if (offset === 0) {
		return `${label} GMT`;
	}

	return `${label} ${formatTzOffset(offset)}`;
};

export const getFormattedUTCDate = date => {
	return date ? moment.utc(date).format('ll') : '---';
};

export const getFormattedUTCDateTime = date => {
	return date ? moment.utc(date).format('lll') : '---';
};

export const getNoOffsetISODate = date => {
	const iso = date.toISOString();

	return `${iso.split('T')[0]}T00:00:00.000Z`;
};

export const getLocalDateTimeString = date => {
	const iso = moment(date).format('YYYY-MM-DDTHH:mm:ss');

	return `${iso}.000Z`;
};

// calendar is grayed out 1 month after the cutOffDate, so if we want to have a max available date
// set to "last day of October 2 years from now", we need to set cutOffDate to first day of October
export const getShipmentCutOffDate = maxDate => {
	const now = new Date();
	const year = moment(now).add(2, 'years').get('year');
	const maxShipmentDate = moment([year, 9, 1]).toDate();

	if (maxDate) {
		const maxDateMoment = moment(maxDate);

		return maxDateMoment.isBefore(maxShipmentDate) ? maxDateMoment : maxShipmentDate;
	}

	return maxShipmentDate;
};

export const isBeforeToday = date => {
	return moment().isAfter(date, 'day');
};

export const formatFuturesContractMonthToLetterCode = date => {
	return ContractMonthCodes[moment(date).utc().month()];
};

export const isNextMonthOrAfter = (date1, date2) => {
	return moment(date1).isAfter(moment(date2), 'month');
};

export const isAfter = (date1, date2) => {
	return moment(date1).isAfter(moment(date2));
};

export const getSpreadMonths = (date1, date2) => {
	const isChinese = moment.locale() === Languages.Chinese.moment;
	const format = isChinese ? 'YY年MMM' : 'MMM YY';

	const formattedDate1 = moment(date1).utc().format(format);
	const formattedDate2 = moment(date2).utc().format(format);

	return [formattedDate1, formattedDate2];
};

export const getSpreadMultiMonths = (period1, period2) => {
	return [
		formatDateRange({
			startDate: parseDateStringAsUTC(period1.startDate),
			endDate: parseDateStringAsUTC(period1.endDate),
			format: period1.format,
			shortYear: true,
		}),
		formatDateRange({
			startDate: parseDateStringAsUTC(period2.startDate),
			endDate: parseDateStringAsUTC(period2.endDate),
			format: period2.format,
			shortYear: true,
		}),
	];
};

export const formatMultiMonthsSpread = (date1, date2) => {
	const [formattedDate1, formattedDate2] = getSpreadMultiMonths(date1, date2);

	return `${formattedDate1} × ${formattedDate2}`;
};

export const getNearestFullTimeFromDateRange = dateRange => {
	return moment().startOf(dateRange).unix();
};

export const isDateInRange = (dateToCheck, dateRange) => {
	const date = moment(dateToCheck);
	const now = moment();
	const rangeStart = moment().startOf(dateRange);

	return date.isBetween(rangeStart, now, null, '[)');
};

export const isValidShipment = (shipmentStartDate, shipmentEndDate, mode) => {
	const now = moment.utc();
	const firstDay = now.clone().startOf(mode === Dateformat.Months ? 'month' : 'day');
	const shipmentStart = moment.utc(shipmentStartDate);
	const shipmentEnd = moment.utc(shipmentEndDate);

	return shipmentStart.isSameOrAfter(firstDay) && shipmentEnd.isSameOrAfter(now);
};
